<template>
    <div
        v-if="sharedItem && isProcess"
        class="caption success--text d-flex align-center"
    >
        <w-icon
            color="success"
            value="CIRCLE"
            left
            small
        />
        {{ $trans('Active') }}
    </div>
    <div
        v-else-if="sharedItem && date && !isTimeout"
        class="caption"
    >
        <div v-if="inline" class="d-flex align-center font-weight-bold text-truncate primary--text">
            <w-icon
                value="CALENDAR_CLOCK"
                small
                color="primary"
                class="mr-1"
            />
            <label v-if="label" class="mr-1">{{ label }}</label>
            <span>{{ date | dateFormat('DD MMMM, YYYY') }}</span>
            <span class="ml-1">{{ date | dateFormat('HH:mm') }}</span>
        </div>
        <div v-else>
            <label v-if="label" class="mb-1 d-block font-weight-bold">{{ label }}</label>
            <div class="d-flex align-start">
                <w-icon
                    small
                    left
                    color="primary"
                    value="CALENDAR_CLOCK"
                />
                <div>
                    {{ date | dateFormat('DD MMMM, YYYY') }}
                </div>
            </div>
            <div class="d-flex align-start">
                <v-icon small left color="primary">mdi-clock</v-icon>
                <div>
                    {{ date | dateFormat('HH:mm') }}
                </div>
            </div>
        </div>
    </div>
    <div
        v-else-if="isTimeout"
        class="d-flex align-center caption error--text"
    >
        <w-icon
            color="error"
            value="CIRCLE"
            small
            left
        />
        {{ end | dateFromNow }}
    </div>
</template>

<script>
import questionsListSharedPeriodMixin from './mixins/questionsListSharedPeriodMixin'

export default {
    name: 'QuestionsListSharedPeriod',
    mixins: [questionsListSharedPeriodMixin],
    props: {
        label: String,
        inline: {
            type: Boolean,
            default: false
        },
        showBegin: {
            type: Boolean,
            default: false
        },
        showEnd: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        date() {
            if(this.begin && this.showBegin) {
                return this.begin
            }

            if(this.end && this.showEnd) {
                return this.end
            }

            return null
        }
    }
}
</script>

<style lang=scss>

</style>
