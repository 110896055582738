import { findSharedItem } from '@apps/questions/components/QuestionsList/utils'
import moment from 'moment'

export default {
    props: {
        item: Object,
        sharedType: String,
        sharedUuid: String,
    },
    computed: {
        sharedItem() {
            return findSharedItem(this.item, this.sharedType, this.sharedUuid)
        },
        begin() {
            if(!this.sharedItem) return null

            return this.sharedItem.options && this.sharedItem.options.begin
                ? this.sharedItem.options.begin
                : null
        },
        end() {
            if(!this.sharedItem) return null

            return this.sharedItem.options && this.sharedItem.options.end
                ? this.sharedItem.options.end
                : null
        },
        fromNow() {
            if(!this.end) return null

            return moment().from(this.end)
        },
        isProcess() {
            if(!this.begin) return true
            if(!this.end) return true

            const begin = moment(this.begin)
            const end = moment(this.end)

            return moment().isBetween(begin, end)
        },
        isTimeout() {
            if(!this.end) return false

            const end = moment(this.end)

            return moment().isAfter(end)
        },
        isDiffLess12Hours() {
            if(!this.begin) return false
            if(!this.end) return false

            const begin = moment(this.begin)
            const end = moment(this.end)

            return end.diff(begin, 'hours') < 12
        }
    }
}
